import { DefaultTheme } from "styled-components";

interface MyTheme extends DefaultTheme {
    color: {
        black: string;
        blue: string;
    };
    fontSize: {
        tiny: string;
        normal: string;
        big: string;
        large: string;
        huge: string;
        gigantic: string;
    };
    fontWeight: {
        thin: string;
        common: string;
        bold: string;
        thick: string;
    };
    imageShadow: string;
}

const myTheme: MyTheme = {
    color: {
        black: "#000023",
        blue: "#4F66C9",
    },
    fontSize: {
        tiny: "0.75rem",
        normal: "1rem",
        big: "1.5rem",
        large: "2.25rem",
        huge: "3rem",
        gigantic: "3.5rem",
    },
    fontWeight: {
        thin: "400",
        common: "500",
        bold: "600",
        thick: "700",
    },
    imageShadow: "drop-shadow(16px 16px 35px rgba(110, 110, 110, 0.4));",
};

export { myTheme };
