import React from "react";
import { ThemeProvider } from "styled-components";
import { myTheme } from "./src/MyTheme.ts";

// export const wrapRootElement = ({ element }) => (
//     <ThemeProvider theme={myTheme}>{element}</ThemeProvider>
// );

export const wrapPageElement = ({ element }) => {
    return <ThemeProvider theme={myTheme}>{element}</ThemeProvider>
  }
